import React from "react";
import { handleAuthentication } from "../utils/auth";
import { PlaceholderMessage } from "../components/PlaceholderMessage";

const Callback = () => {
  handleAuthentication();

  return <PlaceholderMessage>Loading...</PlaceholderMessage>;
};

export default Callback;
